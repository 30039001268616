import React, { useState, useEffect } from "react";
import Logo from "../components/Logo";
import Footer from "../components/Footer";
import Form from "../components/Form";
import "../components/layout.css";
import PlaidButton from "../components/PlaidButton";

// Sandbox temporary
import config from "../config";

const useEnrollmentForm = (callback) => {
  const [inputs, setInputs] = useState({});
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    callback(e);
  };

  const handleInputChange = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
  };
};

let handler;

const IndexPage = (props) => {
  const [isSubmitted, setSubmission] = useState(false);
  const [partnerForm, setPartnerForm] = useState({});
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const { inputs, handleInputChange } = useEnrollmentForm({});
  const [identifier, setIdentifer] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVisible, setVisibility] = useState(true);

  useEffect(() => {
    if (isSubmitted) {
      putLocationPartner(partnerForm);
    }
  }, [isSubmitted, partnerForm]);

  useEffect(() => {
    var vars = {},
      hash;
    var hashes =
      props.location.href &&
      props.location.href
        .slice(props.location.href.indexOf("?") + 1)
        .split("&");

    if (hashes) {
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        vars[hash[0]] = hash[1];
      }
      setLoading(true);
    }

    setName(vars.name);
    setIdentifer(vars.identifier);

    // return vars;
  }, [name, identifier]);

  function putLocationPartner(partnerData) {
    let partnerName =
      inputs.firstName || inputs.lastName
        ? `${inputs.firstName}%20${inputs.lastName}`
        : name;

    let url = `${config.DM_Host}/v2/register/partner/bank?`;

    let id = identifier
      ? `identifier=${identifier}`
      : `identifier=${partnerName}`;

    fetch(url + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "DigitalMint-Token": config.DM_Token,
      },
      body: JSON.stringify(partnerData),
    })
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  const handleOnSuccess = (token, metadata) => {
    const partnerData = {
      PublicToken: token,
      LinkSessionID: metadata.link_session_id,
    };

    setPartnerForm(partnerData);
    setSubmission(true);
  };

  const handleOnExit = (callback) => {
    setVisibility(true);
  };

  const handleOnEvent = (eventName, metadata) => {
    console.log(eventName, metadata);
  };

  return (
    <>
      <div className="app">
        {loading && (
          <div className="container">
            <div></div>
            <div className="main">
              <Logo height="50" className="logo" />
              {isSubmitted && (
                <>
                  <p style={{ color: "#fff", textAlign: "center" }}>
                    Your information has been received. Thank you for partnering
                    with DigitalMint. <br /> We'll contact you for next steps.
                  </p>
                </>
              )}
              {(name || (!name && !identifier)) && !isSubmitted && (
                <div className="card">
                  <Form
                    handleInputChange={handleInputChange}
                    inputs={inputs}
                    errors={errors}
                    setErrors={setErrors}
                    handler={handler}
                    env={config.Environment}
                    defaultValue={name}
                    product={["auth", "transactions", "assets"]}
                    publicKey={config.Key}
                    handleOnSuccess={handleOnSuccess}
                    handleOnExit={handleOnExit}
                    handleOnEvent={handleOnEvent}
                  />
                </div>
              )}
              {identifier && !isSubmitted && (
                <div
                  className="btn-contain"
                  onClick={() => setVisibility(!isVisible)}
                >
                  {isVisible && (
                    <PlaidButton
                      env={config.Environment}
                      product={["auth", "transactions", "assets"]}
                      publicKey={config.Key}
                      handleOnSuccess={handleOnSuccess}
                      handleOnExit={handleOnExit}
                      handleOnEvent={handleOnEvent}
                    />
                  )}
                </div>
              )}
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default IndexPage;

import React from "react";
import PlaidLink from "react-plaid-link";

const PlaidButton = ({
  env,
  product,
  publicKey,
  handleOnExit,
  handleOnSuccess
}) => {
  return (
    <PlaidLink
      style={{
        border: "2px solid #1e202b",
        background: "white",
        color: "#1e202b",
        fontSize: "15px",
        fontWeight: 700,
        borderRadius: "30px",
        padding: "18px 40px",
        cursor: "pointer",
        outline: "none"
      }}
      clientName="DigitalMint"
      env={env}
      product={product}
      publicKey={publicKey}
      onExit={handleOnExit}
      onSuccess={handleOnSuccess}
    >
      Become A Partner
    </PlaidLink>
  );
};

export default PlaidButton;

import React from "react";
import Input from "./Input";
// import PlaidLink from "react-plaid-link";
import PlaidButton from "./PlaidButton";

const Form = ({
  handleInputChange,
  inputs,
  errors,
  product,
  publicKey,
  handleOnExit,
  handleOnSuccess,
  defaultValue,
  env,
  setErrors,
}) => {
  const d = defaultValue && defaultValue.split("%20");
  let f = d && d[0];
  let l = d && d[1];

  return (
    <>
      <h2>Partner with Us!</h2>
      <br></br>
      <br></br>
      <Input
        onChange={handleInputChange}
        value={inputs.firstName}
        name="firstName"
        id="firstName"
        label="First Name"
        // placeholder="Satoshi"
        placeholder={f}
        error={errors.firstName}
        required
      />
      <Input
        key={"last"}
        onChange={handleInputChange}
        value={inputs.lastName}
        name="lastName"
        id="lastName"
        label="Last Name"
        placeholder={l}
        error={errors.lastName}
        required
      />
      <br></br>
      <PlaidButton
        product={product}
        env={env}
        handleOnSuccess={handleOnSuccess}
        handleOnExit={handleOnExit}
        publicKey={publicKey}
      />
      <br></br>
      <small>
        <strong>
          <a
            href="https://www.digitalmint.io/become-a-partner/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </strong>
      </small>
    </>
  );
};

export default Form;

import React from "react";
import Particles from "react-particles-js";

const Footer = () => {
  return (
    <div className="footer">
      <small>
        Copyright © {new Date().getFullYear()} Red Leaf Chicago, LLC dba{" "}
        <a
          href="https://www.digitalmint.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          DigitalMint
        </a>
        . All rights reserved.
      </small>
      <br></br>
      <small>
        DigitalMint may use Plaid Technologies, Inc. (“Plaid”) to verify your
        bank account and confirm your bank account balance prior to approving a
        transaction. DigitalMint only shares your information with Plaid in
        accordance with this Privacy Policy. Information shared with Plaid is
        treated by Plaid in accordance with its Privacy Policy, available at{" "}
        <a
          href="https://plaid.com/legal/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://plaid.com/legal/
        </a>
        .
      </small>
      <Particles
        params={{
          particles: {
            number: {
              value: 20
            },
            size: {
              value: 2
            },
            color: {
              value: ["#ffffff"]
            }
          },
          line_linked: {
            color: "#ffffff"
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse"
              }
            }
          }
        }}
        style={{
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: -1
        }}
      />
    </div>
  );
};

export default Footer;

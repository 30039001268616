const production = process.env.NODE_ENV !== "development";
export const PLAID_SANDBOX_KEY = "ef0d0b0059a3219045abf02be599f6";
export const PLAID_PUBLIC_KEY = "ef0d0b0059a3219045abf02be599f6";
export const PLAID_PRODUCTION = "production";
export const PLAID_DEVELOPMENT = "development";
export const PLAID_SANDBOX = "sandbox";

export const DIGITALMINT_QA = "https://api.qa.digital-mint.com";
export const DIGITALMINT_PROD = "https://api.digital-mint.com";
export const DM_TOKEN = "1296ac39-d0d5-44f4-bb94-d9fc6b70de83";

export default {
  Environment: production ? PLAID_PRODUCTION : PLAID_SANDBOX,
  Key: production ? PLAID_PUBLIC_KEY : PLAID_PUBLIC_KEY,
  DM_Token: DM_TOKEN,
  DM_Host: production ? DIGITALMINT_PROD : DIGITALMINT_QA,
};

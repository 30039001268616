import React from "react";

const Logo = ({ height, width, style }) => (
  <svg
    height={height}
    width={width}
    fill="white"
    className="logo"
    viewBox="0 0 169.78 26.71"
  >
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M4.54,18.54Q6,16.79,7.59,15l2.33-2.62L11.14,11l2.34-2.59,1.21-1.36c.79-.87,1.57-1.74,2.34-2.62.51-.57,1-1.15,1.51-1.73C19.33,1.83,20.11.92,20.87,0l-2.7,2.33-2.31,2-1.17,1-2.28,2L11.14,8.55l-2.57,2.3-1,.88C6,13.2,4.2,14.77,2.34,16.46v-6a8.1,8.1,0,0,1,8.1-8.11h6.28L19.41,0h-9A10.43,10.43,0,0,0,0,10.44V20.87H2.61c.63-.77,1.27-1.55,1.93-2.33"
        />
        <path
          className="cls-1"
          d="M7.59,18.54H6.29l-2,2.33h6.19c.23,0,.47,0,.7,0a10.62,10.62,0,0,0,2.34-.42,10.46,10.46,0,0,0,7.39-10V1.87L18.54,4.54v5.9A8.09,8.09,0,0,1,17,15.15V6.27L14.69,8.94v8.39a7.67,7.67,0,0,1-1.21.62V10.33L11.14,13v5.51c-.23,0-.47,0-.7,0H9.92V14.39L7.59,17.06v1.48"
        />
        <path
          className="cls-2"
          d="M41.2,18.81a5.15,5.15,0,0,1-3.93,1.59,5.57,5.57,0,0,1-4.5-2,7.14,7.14,0,0,1-1.52-4.69,6.85,6.85,0,0,1,1.87-4.85A6.72,6.72,0,0,1,40.9,7.58V4.63a4.35,4.35,0,0,0-.21-1.71,1.33,1.33,0,0,0-.44-.54,2.31,2.31,0,0,0-.77-.22C39.14,2.1,39,2,39,1.72s.24-.36.71-.46A8.7,8.7,0,0,0,42,.55,3.11,3.11,0,0,1,43.12.08a.28.28,0,0,1,.32.32.93.93,0,0,1,0,.32,5.41,5.41,0,0,0-.31,1.91c0,.78,0,1.4,0,1.89V16a6.67,6.67,0,0,0,.18,2,1.37,1.37,0,0,0,.36.58.71.71,0,0,0,.43.13l.84-.18c.27,0,.41.12.41.36a.42.42,0,0,1-.18.35,2.3,2.3,0,0,1-.59.32,4.91,4.91,0,0,0-.7.38,5.66,5.66,0,0,0-.49.37,1.38,1.38,0,0,1-.74.4C42,20.65,41.52,20,41.2,18.81Zm-.3-9.15a4.11,4.11,0,0,0-3.52-1.74,3.29,3.29,0,0,0-2.71,1.3A5.91,5.91,0,0,0,33.58,13,6.58,6.58,0,0,0,35,17.4,4,4,0,0,0,38.14,19,3.33,3.33,0,0,0,41,17.65a10.54,10.54,0,0,1-.07-1.21Z"
        />
        <path
          className="cls-2"
          d="M47.37,20.26c-.62,0-.94-.17-.94-.52s.18-.4.52-.4l.7.07a1.07,1.07,0,0,0,.53-.12.84.84,0,0,0,.33-.51,13.25,13.25,0,0,0,.23-3.16v-4.2a4.84,4.84,0,0,0-.19-1.76,1.21,1.21,0,0,0-.43-.5A2.35,2.35,0,0,0,47.33,9c-.36,0-.55-.2-.55-.44s.24-.37.73-.47a9.21,9.21,0,0,0,2.29-.69A3.27,3.27,0,0,1,51,6.86a.29.29,0,0,1,.32.32,1,1,0,0,1-.06.33,14.54,14.54,0,0,0-.32,3.8v4.31a13.83,13.83,0,0,0,.18,3,1.14,1.14,0,0,0,.38.67,1.07,1.07,0,0,0,.53.12l.7-.07c.34,0,.52.13.52.4s-.32.52-.94.52a6.84,6.84,0,0,1-1.14-.11,7.62,7.62,0,0,0-1.3-.1,8.07,8.07,0,0,0-1.33.1A7.17,7.17,0,0,1,47.37,20.26ZM51.21,2.71a1.36,1.36,0,0,1-.4,1,1.39,1.39,0,0,1-1.07.42,1.38,1.38,0,0,1-1.05-.4,1.26,1.26,0,0,1-.37-.87,1.73,1.73,0,0,1,.12-.72,1.76,1.76,0,0,1,.33-.43,1.54,1.54,0,0,1,1-.38,1.37,1.37,0,0,1,1,.39A1.39,1.39,0,0,1,51.21,2.71Z"
        />
        <path
          className="cls-2"
          d="M58.53,15.55c-.54.46-.81.76-.81.89a.7.7,0,0,0,.1.38,1.15,1.15,0,0,0,.46.32,9.9,9.9,0,0,0,2.73.57,19.25,19.25,0,0,1,3.23.59,7.89,7.89,0,0,1,2.11.91A3.17,3.17,0,0,1,67.89,22a4,4,0,0,1-2,3.32,9.39,9.39,0,0,1-9.4.25,3.44,3.44,0,0,1-1.77-2.94q0-2.26,3-3.42c-1.09-.33-1.73-.74-1.92-1.26a1.33,1.33,0,0,1-.09-.49q0-.69,2-2.34a4.2,4.2,0,0,1-2-3.76A4.14,4.14,0,0,1,57.08,8.2,5.71,5.71,0,0,1,61,6.88a5.9,5.9,0,0,1,2.06.37,5.16,5.16,0,0,1,1.74-2.17,3.29,3.29,0,0,1,2-.7,1.64,1.64,0,0,1,1.61.85,1.1,1.1,0,0,1,.14.57.74.74,0,0,1-.34.59,1.09,1.09,0,0,1-.65.27,1.33,1.33,0,0,1-.89-.27,1.59,1.59,0,0,0-1-.43,1.39,1.39,0,0,0-1,.46A3,3,0,0,0,64,7.7a4.14,4.14,0,0,1,2.19,3.7,4.09,4.09,0,0,1-1.52,3.19,5.78,5.78,0,0,1-3.86,1.34A6.13,6.13,0,0,1,58.53,15.55Zm.26,3.94a2.73,2.73,0,0,0-2,2.68,2.66,2.66,0,0,0,1.34,2.18,6.15,6.15,0,0,0,3.59,1c2.11,0,3.43-.46,4-1.38A2.11,2.11,0,0,0,66,22.83a2.55,2.55,0,0,0-.21-1.09,2,2,0,0,0-.75-.8,9.76,9.76,0,0,0-3.86-1.07A18.46,18.46,0,0,1,58.79,19.49ZM59,13.9a3,3,0,0,0,1.05.79A2.86,2.86,0,0,0,61.2,15a2.61,2.61,0,0,0,1-.2,2.25,2.25,0,0,0,.79-.59,3.12,3.12,0,0,0,.71-2.08,6.9,6.9,0,0,0-.22-2,3.42,3.42,0,0,0-.63-1.25,2.86,2.86,0,0,0-2.35-1.05A2.38,2.38,0,0,0,58.17,9.6,4.28,4.28,0,0,0,58,11a4.77,4.77,0,0,0,.27,1.61A3.86,3.86,0,0,0,59,13.9Z"
        />
        <path
          className="cls-2"
          d="M70,20.26c-.62,0-.94-.17-.94-.52s.18-.4.52-.4l.7.07a1.07,1.07,0,0,0,.53-.12.84.84,0,0,0,.33-.51,13.25,13.25,0,0,0,.23-3.16v-4.2a5.09,5.09,0,0,0-.19-1.76,1.21,1.21,0,0,0-.43-.5A2.35,2.35,0,0,0,70,9c-.36,0-.55-.2-.55-.44s.24-.37.73-.47a9.21,9.21,0,0,0,2.29-.69,3.27,3.27,0,0,1,1.19-.49.29.29,0,0,1,.32.32,1,1,0,0,1-.06.33,14.54,14.54,0,0,0-.32,3.8v4.31a14.89,14.89,0,0,0,.17,3,1.2,1.2,0,0,0,.39.67,1,1,0,0,0,.53.12l.7-.07c.34,0,.51.13.51.4s-.31.52-.93.52a6.74,6.74,0,0,1-1.14-.11,7.62,7.62,0,0,0-1.3-.1,8.07,8.07,0,0,0-1.33.1A7.17,7.17,0,0,1,70,20.26ZM73.88,2.71a1.36,1.36,0,0,1-.4,1,1.4,1.4,0,0,1-1.08.42,1.37,1.37,0,0,1-1-.4A1.26,1.26,0,0,1,71,2.86a1.73,1.73,0,0,1,.12-.72,1.76,1.76,0,0,1,.33-.43,1.54,1.54,0,0,1,1-.38,1.37,1.37,0,0,1,1,.39A1.39,1.39,0,0,1,73.88,2.71Z"
        />
        <path
          className="cls-2"
          d="M85.31,8.26c0,.25-.15.38-.45.38l-4-.05v7.74c0,1.45.35,2.3,1,2.57a1.93,1.93,0,0,0,.75.13,2,2,0,0,0,.79-.15,4.24,4.24,0,0,0,.64-.31,5.41,5.41,0,0,0,.47-.32.69.69,0,0,1,.37-.14.4.4,0,0,1,.32.19.69.69,0,0,1,.14.39c0,.33-.42.72-1.25,1.18a4.85,4.85,0,0,1-2.38.53,2.69,2.69,0,0,1-2.25-1,4.46,4.46,0,0,1-.81-2.84v-8h-.86c-.67,0-1-.17-1-.51s.18-.43.56-.52a2.21,2.21,0,0,0,1-.5,2.87,2.87,0,0,0,.66-.81,4.65,4.65,0,0,0,.42-.94c.1-.32.19-.62.28-.89.2-.62.43-.92.69-.92s.45.26.45.79V7.41l3.93-.08c.19,0,.35.23.46.69C85.29,8.13,85.31,8.2,85.31,8.26Z"
        />
        <path
          className="cls-2"
          d="M99.78,18.18c.26,0,.4.12.4.37s-.27.48-.81.7a4.73,4.73,0,0,0-1.13.61c-.22.18-.37.32-.48.4a.52.52,0,0,1-.36.14.88.88,0,0,1-.7-.35,7.07,7.07,0,0,1-.75-1.5A5.07,5.07,0,0,1,94,19.93a5.93,5.93,0,0,1-2.25.47,4.61,4.61,0,0,1-3.27-1.08,3.83,3.83,0,0,1-1.16-2.94,3.5,3.5,0,0,1,1.75-3,7.91,7.91,0,0,1,4.48-1.17,10.71,10.71,0,0,1,2.14.28V11.36q0-3.58-3-3.59A2.49,2.49,0,0,0,90.3,9.25c-.43.89-.86,1.33-1.28,1.33a1.25,1.25,0,0,1-.8-.27.81.81,0,0,1-.35-.67,1.46,1.46,0,0,1,.42-.9,4.33,4.33,0,0,1,1.12-.9,7.05,7.05,0,0,1,3.62-1q3.5,0,4.51,2.27a4.77,4.77,0,0,1,.38,2v5.57a2.83,2.83,0,0,0,.21,1.38.65.65,0,0,0,.58.31,1.56,1.56,0,0,0,.62-.11A1,1,0,0,1,99.78,18.18ZM95.7,13.75a5.12,5.12,0,0,0-2.55-.57,3.75,3.75,0,0,0-2.46.76A2.55,2.55,0,0,0,89.76,16a3.3,3.3,0,0,0,.71,2.16A2.29,2.29,0,0,0,92.3,19a3.85,3.85,0,0,0,3.44-1.57,7.71,7.71,0,0,1,0-.87Z"
        />
        <path
          className="cls-2"
          d="M102.05,20.26c-.61,0-.92-.17-.92-.52s.17-.4.52-.4l.69.07a1.08,1.08,0,0,0,.54-.12.83.83,0,0,0,.32-.51,13.57,13.57,0,0,0,.22-3.16V5.22q0-2.32-1-2.46c-.47-.08-.7-.23-.7-.46s.22-.37.65-.46a8.12,8.12,0,0,0,1.19-.33l.94-.39.69-.33a1,1,0,0,1,.41-.14A.28.28,0,0,1,106,1a1.25,1.25,0,0,1,0,.32,5.41,5.41,0,0,0-.31,1.91c0,.78,0,1.41,0,1.9V15.62a15,15,0,0,0,.17,3,1.14,1.14,0,0,0,.38.67,1.07,1.07,0,0,0,.53.12l.7-.07c.34,0,.51.13.51.4s-.3.52-.92.52a6.12,6.12,0,0,1-1.1-.11,7.35,7.35,0,0,0-1.31-.1,8,8,0,0,0-1.35.1A6.74,6.74,0,0,1,102.05,20.26Z"
        />
        <path
          className="cls-2"
          d="M112.48,20.26c-.61,0-.92-.17-.92-.52s.17-.4.52-.4l.7.07a1,1,0,0,0,.52-.12.8.8,0,0,0,.33-.51,13.57,13.57,0,0,0,.22-3.16V11.45a5.13,5.13,0,0,0-.18-1.77,1,1,0,0,0-.43-.5,2.31,2.31,0,0,0-.79-.22c-.36-.06-.54-.2-.54-.43s.24-.37.71-.46a9.56,9.56,0,0,0,2.29-.7,3,3,0,0,1,1.17-.49.28.28,0,0,1,.31.32,1,1,0,0,1-.06.32c0,.11-.08.25-.13.4A8.53,8.53,0,0,0,116,9.38a5.59,5.59,0,0,1,3.12-2.33,4.14,4.14,0,0,1,1.2-.17,3.72,3.72,0,0,1,4,2.9,5.8,5.8,0,0,1,3.28-2.69,4.16,4.16,0,0,1,1.3-.21,4.07,4.07,0,0,1,3.15,1.19,5.22,5.22,0,0,1,1.12,3.7v3.85a15,15,0,0,0,.17,3,1.14,1.14,0,0,0,.38.67,1,1,0,0,0,.53.12l.69-.07c.35,0,.52.13.52.4s-.31.52-.93.52a6.65,6.65,0,0,1-1.14-.11,7.5,7.5,0,0,0-1.29-.1,7.91,7.91,0,0,0-1.31.1,7,7,0,0,1-1.15.11c-.63,0-.94-.17-.94-.52s.17-.4.52-.4l.7.07a1.07,1.07,0,0,0,.53-.12.84.84,0,0,0,.33-.51,13.45,13.45,0,0,0,.22-3.16V12.44c0-2-.54-3.29-1.64-3.73a3.08,3.08,0,0,0-1.14-.21,3,3,0,0,0-1.14.21,3.56,3.56,0,0,0-1,.64,5.7,5.7,0,0,0-1.54,2.4v3.87a15,15,0,0,0,.17,3,1.14,1.14,0,0,0,.38.67,1.07,1.07,0,0,0,.53.12l.7-.07c.34,0,.51.13.51.4s-.31.52-.93.52a6.63,6.63,0,0,1-1.13-.11,7.5,7.5,0,0,0-1.29-.1,8,8,0,0,0-1.32.1,7,7,0,0,1-1.15.11c-.62,0-.93-.17-.93-.52s.17-.4.51-.4l.7.07a1.07,1.07,0,0,0,.53-.12.89.89,0,0,0,.34-.51,14.05,14.05,0,0,0,.22-3.16V12.44c0-2-.55-3.29-1.64-3.73a3.23,3.23,0,0,0-2.29,0,3.61,3.61,0,0,0-1,.64,5.66,5.66,0,0,0-1.52,2.4v3.87a15.38,15.38,0,0,0,.16,3,1.14,1.14,0,0,0,.38.67,1.07,1.07,0,0,0,.53.12l.7-.07c.34,0,.52.13.52.4s-.31.52-.93.52a6.12,6.12,0,0,1-1.1-.11,7.35,7.35,0,0,0-1.31-.1,8,8,0,0,0-1.35.1A6.65,6.65,0,0,1,112.48,20.26Z"
        />
        <path
          className="cls-2"
          d="M137.68,20.26c-.63,0-.94-.17-.94-.52s.18-.4.52-.4l.7.07a1.07,1.07,0,0,0,.53-.12.89.89,0,0,0,.34-.51,14.05,14.05,0,0,0,.22-3.16v-4.2a4.84,4.84,0,0,0-.19-1.76,1.21,1.21,0,0,0-.43-.5,2.35,2.35,0,0,0-.78-.21c-.37,0-.56-.2-.56-.44s.24-.37.73-.47a9.21,9.21,0,0,0,2.29-.69,3.27,3.27,0,0,1,1.19-.49.29.29,0,0,1,.32.32,1,1,0,0,1-.06.33,14.54,14.54,0,0,0-.32,3.8v4.31a14.89,14.89,0,0,0,.17,3,1.2,1.2,0,0,0,.39.67,1.07,1.07,0,0,0,.53.12l.7-.07c.34,0,.51.13.51.4s-.31.52-.93.52a6.74,6.74,0,0,1-1.14-.11,7.62,7.62,0,0,0-1.3-.1,8.07,8.07,0,0,0-1.33.1A7.17,7.17,0,0,1,137.68,20.26Zm3.84-17.55a1.36,1.36,0,0,1-.4,1,1.39,1.39,0,0,1-1.07.42,1.38,1.38,0,0,1-1.05-.4,1.26,1.26,0,0,1-.37-.87,1.73,1.73,0,0,1,.12-.72,1.76,1.76,0,0,1,.33-.43,1.56,1.56,0,0,1,1-.38,1.37,1.37,0,0,1,1,.39A1.39,1.39,0,0,1,141.52,2.71Z"
        />
        <path
          className="cls-2"
          d="M145.67,20.26c-.62,0-.92-.17-.92-.52s.17-.4.51-.4l.74.07a1,1,0,0,0,.53-.12.84.84,0,0,0,.33-.51,14.18,14.18,0,0,0,.22-3.16V11.45a4.76,4.76,0,0,0-.19-1.77,1.14,1.14,0,0,0-.45-.49,3.1,3.1,0,0,0-.83-.22c-.37-.06-.56-.21-.56-.44s.24-.37.72-.46a9.89,9.89,0,0,0,2.4-.72,3,3,0,0,1,1.14-.47.28.28,0,0,1,.31.32,1.25,1.25,0,0,1-.05.32,6.9,6.9,0,0,0-.31,2.17,5.81,5.81,0,0,1,2-2.09,4.53,4.53,0,0,1,2.36-.72,5.93,5.93,0,0,1,2,.29,3.59,3.59,0,0,1,1.39.9,5.23,5.23,0,0,1,1.17,3.7v3.85a15,15,0,0,0,.17,3,1.14,1.14,0,0,0,.38.67,1,1,0,0,0,.53.12l.72-.07c.35,0,.52.13.52.4s-.31.52-.94.52a6.86,6.86,0,0,1-1.15-.11,7.62,7.62,0,0,0-1.3-.1,8,8,0,0,0-1.32.1,7.18,7.18,0,0,1-1.17.11c-.62,0-.94-.17-.94-.52s.18-.4.52-.4l.73.07a1.07,1.07,0,0,0,.53-.12.84.84,0,0,0,.33-.51,13.25,13.25,0,0,0,.23-3.16V12.44q0-4-2.91-4a3.58,3.58,0,0,0-3.2,1.86,5.89,5.89,0,0,0-.63,1.68v3.64a15,15,0,0,0,.17,3,1.14,1.14,0,0,0,.38.67,1.07,1.07,0,0,0,.53.12l.74-.07c.34,0,.52.13.52.4s-.31.52-.93.52a6.52,6.52,0,0,1-1.12-.11,7.74,7.74,0,0,0-1.33-.1,8.32,8.32,0,0,0-1.37.1A7,7,0,0,1,145.67,20.26Z"
        />
        <path
          className="cls-2"
          d="M169.67,8.26c0,.25-.16.38-.45.38l-4-.05v7.74c0,1.45.35,2.3,1.05,2.57a1.9,1.9,0,0,0,.75.13,2,2,0,0,0,.79-.15,4.24,4.24,0,0,0,.64-.31,5.41,5.41,0,0,0,.47-.32.69.69,0,0,1,.37-.14.4.4,0,0,1,.32.19.69.69,0,0,1,.14.39c0,.33-.42.72-1.25,1.18a4.89,4.89,0,0,1-2.39.53,2.69,2.69,0,0,1-2.24-1,4.46,4.46,0,0,1-.81-2.84v-8h-.86c-.67,0-1-.17-1-.51s.19-.43.56-.52a2.21,2.21,0,0,0,1-.5,2.87,2.87,0,0,0,.66-.81,4.65,4.65,0,0,0,.42-.94c.1-.32.19-.62.28-.89.2-.62.43-.92.69-.92s.45.26.45.79V7.41l3.93-.08c.19,0,.34.23.46.69C169.65,8.13,169.67,8.2,169.67,8.26Z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
